import * as Yup from "yup";
import { AlertSeverity } from "../components/Alert";
import { AccountStatus, AccountType, CreatablelAccountType } from "../dto/Account";
import { Permission } from "../dto/AccountUser";
import { DistributionType } from "../dto/Distribution";
import { CompanySize, EntityKYCStatus, Industry, TypeOfBusiness } from "../dto/EntityProfile";
import { OfferIssuance, OfferStatus, OfferType } from "../dto/Offer";
import {
  AccreditationStatus,
  EntityAccreditationCriteria,
  IndividualAccreditationCriteria,
} from "../dto/Profile";
import { RegisteredAccountType, TrustCompanyIdentifier } from "../dto/RegisteredAccount";
import {
  FFBABusinessRelationship,
  FFBAFamilyRelationship,
  FFBAFamilyRelationshipType,
  FFBAPositionType,
  FFBARelationshipType,
  InvestmentStatus,
  TransactionServiceType,
  VirtualCurrencyAccessMethod,
} from "../dto/Transaction";
import { KYCStatus } from "../dto/UserProfile";
import { WalletBlockchain } from "../dto/Wallet";
import jsonLogic from "json-logic-js";

function getAllVarValues(obj: any, varValues: string[] = []): string[] {
  if (Array.isArray(obj)) {
    obj.forEach((element) => {
      getAllVarValues(element, varValues);
    });
  } else if (obj !== null && typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      if (key === "var") {
        varValues.push(obj[key]);
      } else {
        getAllVarValues(obj[key], varValues);
      }
    });
  }
  return varValues;
}

export const AUTHENTICATE_AT = "AUTHENTICATE_AT";
export const AUTH_TOKEN = "AUTH_TOKEN";
export const CURRENT_ACCOUNT_ID = "CURRENT_ACCOUNT_ID";
export const ADMIN_ACCOUNT_ID = "ADMIN_ACCOUNT_ID";
export const ASSUME_ROLE = "ASSUME_ROLE";
export const SUPPORTED_BANKS = [
  {
    name: "BMO",
    link: "https://www1.bmo.com/onlinebanking/cgi-bin/netbnx/NBmain",
  },
  {
    name: "SCOTIABANK",
    link: "https://www.scotiaonline.scotiabank.com/online/authentication/authentication.bns",
  },
  {
    name: "RBC",
    link: "https://www.rbcroyalbank.com/ways-to-bank/online-banking/index.html",
  },
  {
    name: "TD",
    link: "https://authentication.td.com/uap-ui/index.html?consumer=easyweb&locale=en_CA#/login/easyweb-getting-started",
  },
];

export const accountTypeLabels: { [key in AccountType]: string } = {
  individual_referral_agent: "Individual Referral Agent",
  entity_referral_agent: "Entity Referral Agent",
  individual_investor: "Individual Investor",
  entity_investor: "Entity Investor",
  joint_investor: "Joint Investor",
  issuer: "Issuer",
  admin: "Admin",
};

export const accountStatusLabels: { [key in AccountStatus]: string } = {
  pending_approval: "Pending Approval",
  approved: "Approved",
  disabled: "Disabled",
  // locked_requires_update: "Locked Requires Update",
};

export const alertSeverityColor: { [key in AlertSeverity]: string } = {
  default: "rgb(0, 0, 0)",
  success: "rgb(118, 202, 102)",
  info: "rgb(160, 195, 255)",
  warning: "rgb(251, 199, 86)",
  error: "rgb(217, 55, 55)",
  neutral: "rgb(224,224,224)",
};

export const documentTypeLabels: { [key: string]: string } = {
  ID_CARD: "ID Card",
  PASSPORT: "Passport",
  UTILITY_BILL: "Utility Bill",
  RESIDENCE_PERMIT: "Residence Permit",
  DRIVING_LICENCE: "Driving Licence",
  DRIVERS_LICENSE: "Drivers License",
  BANK_STATEMENT: "Bank Statement",
  HEALTH_INSURANCE_CARD: "Health Insurance Card",
  INCOME_TAX_STATEMENT: "Income Tax Statement",
  PROPERTY_TAX_STATEMENT: "Property Tax Statement",
};

export const entityKYCProviderStatusLabels: { [key in EntityKYCStatus]?: string } = {
  pending: "Pending",
  declined: "Declined",
  approved: "Approved"
};


export const kycProviderStatusLabels: { [key in KYCStatus]?: string } = {
  pending: "Pending",
  started: "Started",
  submitted: "Submitted",
  declined: "Declined",
  expired: "Expired",
  submission_requested: "Requested",
  resubmission_requested: "Requested",
  abandoned: "Abandoned",
  approved: "Approved",
  // Onfido results
  in_progress: "Onfido: In Progress",
  awaiting_applicant: "Onfido: Awaiting Applicant",
  complete: "Onfido: Complete",
  withdrawn: "Onfido: Withdrawn",
  paused: "Onfido: Paused",
  reopened: "Onfido: Reopened",
  clear: "Onfido: Clear",
  consider: "Onfido: Consider",
  // Plaid
  active: "Plaid: Active",
  success: "Plaid: Success",
  failed: "Plaid: Failed",
  canceled: "Plaid: Canceled",
  pending_review: "Plaid: Pending Review",
};


export const kycProviderLabels: { [key:string]: string } = {
  manual: "Manual",
  onfido: "Onfido",
  veriff: "Veriff"
};

export const kycStatusLabels: { [key in KYCStatus]: string } = {
  pending: "Pending",
  started: "Started",
  submitted: "Submitted",
  declined: "Declined",
  expired: "Expired",
  submission_requested: "Requested",
  resubmission_requested: "Requested",
  abandoned: "Abandoned",
  approved: "Approved",
  // Onfido results
  in_progress: "Onfido: In Progress",
  awaiting_applicant: "Onfido: Awaiting Applicant",
  complete: "Onfido: Complete",
  withdrawn: "Onfido: Withdrawn",
  paused: "Onfido: Paused",
  reopened: "Onfido: Reopened",
  clear: "Onfido: Clear",
  consider: "Onfido: Consider",
  // Plaid
  active: "Plaid: Active",
  success: "Plaid: Success",
  failed: "Plaid: Failed",
  canceled: "Plaid: Canceled",
  pending_review: "Plaid: Pending Review",
  // PEP & Suitability
  review_kyc_hits: "Review KYC Hits",
  review_kyc_questions: "Review KYC Questions",
  review_kyc_hits_and_kyc_questions: "Review KYC Hits & KYC Questions",
  failed_kyc_hits: "Failed KYC Hits",
  failed_kyc_questions: "Failed KYC Questions",
  failed_kyc_hits_and_kyc_questions: "Failed KYC Hits & KYC Questions",
};

export const accreditationStatusLabels: { [key in AccreditationStatus]: string } = {
  accredited: "Accredited Investor",
  eligible: "Eligible Investor",
  neither: "Non-Eligible",
  not_sure: "Not Sure",
};

export const accreditationCriteriaLabels: { [key in string]: string } = {
  accredited_individual_income: "Accredited Individual Income",
  accredited_joint_income: "Accredited Joint Income",
  accredited_total_net_assets: "Accredited Total Net Assets",
  accredited_net_financial_assets: "Accredited Net Financial Assets",
  accredited_investment_fund: "Accredited Investment Fund",
  accredited_shareholders: "Accredited Shareholders",
  accredited_behalf_of_fully_managed_account:
    "Accredited Behalf of Fully Managed Account",
  eligible_individual_income: "Eligible Individual Income",
  eligible_joint_income: "Eligible Joint Income",
  eligible_total_net_assets: "Eligible Total Net Assets",
  eligible_shareholders: "Eligible Shareholders",
};

const individualInvestorAccreditationCriteria = {
  accredited: {
    accredited_individual_income: [
      "My Individual Income is",
      "Above $200,000",
      "(for each of the last 2 years)",
    ],
    accredited_joint_income: [
      "My Joint Income with spouse is",
      "Above $300,000",
      "(for each of the last 2 years)",
    ],
    accredited_net_financial_assets: [
      "My individual Net Financial Assets or joint with spouse is",
      "Above $1M",
      "(excluding primary residence)",
    ],
    accredited_total_net_assets: [
      "My individual Total Net Assets or joint with spouse is",
      "Above $5,000,000",
      "(including primary residence)",
    ],
  },
  eligible: {
    eligible_individual_income: [
      "My Individual Income is",
      "Above $75,000",
      "(for each of the last 2 years)",
    ],
    eligible_joint_income: [
      "My Joint Income with spouse is",
      "Above $125,000",
      "(for each of the last 2 years)",
    ],
    eligible_total_net_assets: [
      "My individual Total Net Assets or joint with spouse is",
      "Above $400,000",
      "(including primary residence)",
    ],
  },
  neither: {},
  not_sure: {},
};

export const accreditationCriteria: {
  [key in AccountType]: {
    [key in AccreditationStatus]: {
      [key in IndividualAccreditationCriteria | EntityAccreditationCriteria]: any;
    };
  };
} = {
  individual_investor: individualInvestorAccreditationCriteria,
  joint_investor: individualInvestorAccreditationCriteria,
  entity_investor: {
    accredited: {
      accredited_total_net_assets: ["Net Assets of at least $5,000,000"],
      accredited_behalf_of_fully_managed_account: [
        "Person acting on behalf of a fully managed account manged by that person",
      ],
      accredited_shareholders: [
        "All of the entity's shareholders are Accredited Investors",
      ],
      accredited_investment_fund: [
        "An investment fund that is advised by a person registered as an advisor or exempt from the registration as an advisor",
      ],
    },
    eligible: {
      eligible_shareholders: ["Majority of eligible shareholders are eligible investors"],
    },
    neither: {},
    not_sure: {},
  },
  issuer: {
    accredited: {},
    eligible: {},
    neither: {},
    not_sure: {},
  },
  admin: {
    accredited: {},
    eligible: {},
    neither: {},
    not_sure: {},
  },
  individual_referral_agent: {
    accredited: {},
    eligible: {},
    neither: {},
    not_sure: {},
  },
  entity_referral_agent: {
    accredited: {},
    eligible: {},
    neither: {},
    not_sure: {},
  },
};

export const walletBlockchainLabels: { [key in WalletBlockchain]: string } = {
  tezos: "Tezos",
  bitcoin: "Bitcoin",
  ethereum: "Ethereum",
};

export const ffbaRelationshipsLabels: { [key in FFBARelationshipType]: string } = {
  family: "Family Member",
  close_personal_friend: "Close Personal Friend",
  close_business_associate: "Close Business Associate",
  founder: "Founder",
  director: "Director",
  executive: "Executive",
  other_employee: "Other Employee",
  controlling_person: "Controlling Person",
};

export const ffbaFamilyRelationshipLabels: {
  [key in FFBAFamilyRelationship]: string;
} = {
  family: "Family Member",
  close_personal_friend: "Close Personal Friend",
  close_business_associate: "Close Business Associate",
};

export const ffbaBusinessRelationshipLabels: {
  [key in FFBABusinessRelationship]: string;
} = {
  founder: "Founder",
  director: "Director",
  executive: "Executive Officer",
  controlling_person: "Controlling Person",
  other_employee: "Other Employee",
};

export const ffbaBusinessRelationshipPhrase: {
  [key in FFBABusinessRelationship]: string;
} = {
  founder: "I am a Founder",
  director: "I am a Director",
  executive: "I am an Executive Officer",
  controlling_person: "I am a Controlling Person",
  other_employee: "I am an Other Employee",
};

export const ffbaFamilyRelationshipTypeLabels: {
  [key in FFBAFamilyRelationshipType]: string;
} = {
  child: "Child",
  spouse: "Spouse",
  sister: "Sister",
  parent: "Parent",
  partner: "Partner",
  brother: "Brother",
  grandparent: "Grandparent",
};

export const ffbaPositionLabels: {
  [key in FFBAPositionType]: string;
} = {
  controlling_person: "Controlling Person",
  director: "Director",
  executive: "Executive",
  founder: "Founder",
};

export const fiatMethods = ["eft", "wire", "bill", "registered_account", "pad"];

export const paymentMethodLabels: { [key: string]: string } = {
  transfer: "Transfer",
  drip: "DRIP",
  drip_and_cash: "DRIP and Cash",
  drip_and_virtual_currency: "DRIP and Virtual Currency",
  eft: "EFT",
  wire: "Wire Transfer",
  bill: "Bill Payment",
  reinvestment: "Reinvesment",
  registered_account: "Registered Account",
  virtual_currency: "Virtual Currency",
  e_transfer: "e-Transfer",
  direct_to_issuer: "Direct to Issuer",
};

export const offerManagementSettingLabels: { [key: string]: { [key: string]: string } } =
  {
    individual_referral_agent: {},
    entity_referral_agent: {},
    issuer: {
      blockchain: "Blockchain",
      restrictions: "Investment Restrictions",
      "payment-methods": "Payment Methods",
      distributions: "Distributions",
    },
    admin: {
      issuer: "Issuer",
      blockchain: "Blockchain",
      restrictions: "Investment Restrictions",
      "subscription-documents": "Subscription Documents",
      "payment-methods": "Payment Methods",
      commission: "Commission",
      distributions: "Distributions",
    },
  };

export const industryLabels: { [key in Industry]: string } = {
  argiculture: "Argiculture",
  aviation: "Aviation",
  communication_services: "Communication Services",
  consumer_discretionary: "Consumer Discretionary",
  consumer_staples: "Consumer Staples",
  energy: "Energy",
  financials: "Financials",
  food_and_beverage: "Food and Beverage",
  healthcare: "Healthcare",
  industrials: "Industrials",
  information_technology: "Information Technology",
  materials: "Materials",
  real_estate: "Real Estate",
  telecommunications: "Telecommunications",
  utilities: "Utilities",
  other: "Other",
};

export const typesOfBusinessLabels: { [key in TypeOfBusiness]: string } = {
  corporation: "Corporation",
  partnership: "Partnership",
  sole_proprietorship: "Sole Proprietorship",
  non_profit_or_society: "Non-Profit/Society",
};

export const invitableAccountTypeLabels: { [key in string]: string } = {
  individual_investor: "Individual Investor Account",
  entity_investor: "Entity Investor Account",
  joint_investor: "Joint Investor Account",
};

export const accountTypeFormLabels: { [key in CreatablelAccountType]: string } = {
  individual_investor: "Individual Investor Account",
  entity_investor: "Entity Investor Account",
  issuer: "Issuer Account",
  individual_referral_agent: "Individual Referral Agent",
  entity_referral_agent: "Entity Referral Agent",
  joint_investor: "Joint Investor Account",
};

export const accountReferrerTypeLabels: { [key in string]: string } = {
  individual_investor: "Investor",
  entity_investor: "Investor",
  issuer: "Issuer",
  individual_referral_agent: "Referral Agent",
  entity_referral_agent: "Referral Agent",
  joint_investor: "Investor",
  admin: "Admin",
};

export const riskTolerance = {
  high: "High",
  medium: "Medium",
  low: "Low",
};

export const riskCapacity = {
  high: "High",
  medium: "Medium",
  low: "Low",
};

export const investmentObjective = {
  growth: "Growth",
  balanced: "Balanced",
  income: "Income",
  safety: "Safety",
};

export const liquidityRisk = {
  long_term: "Long term (over 5 years)",
  medium_term: "Medium to long term (3 to 5 years)",
  short_term: "Short term (less than 3 years)",
};

export const kycQuestions = [
  "is_registrant",
  "politically_exposed",
  "using_borrowed_money",
  "account_used_on_behalf",
  "account_opened_on_behalf",
  "more_than_one_account_operator",
  "insider_reporting_issuer_is_related_to_the_securities_issuer",
];

const individualInvestorSuitabilityForm = {
  net_financial_assets: {
    label: "What is the approximate total value of your Net Financial Assets?",
    options: {
      lt100000: "Under $100,000",
      "100000-399999": "$100,000 - $399,999",
      "400000-999999": "$400,000 - $999,999",
      "1000000-1499999": "$1,000,000 - $1,499,999",
      gt1500000: "$1,500,000+",
    },
    type: "select",
    tooltip: `“Net financial assets” refers to your total financial assets, before taxes, but net of any related liabilities.  For this purpose, financial assets are defined to mean cash, securities, or a contract of insurance, or a deposit at a regulated financial institution.  The value of your personal residence should not be included in the calculation of your net financial assets.`,
  },
  pre_tax_annual_income: {
    label:
      "What is the range of your pre-tax annual income over the last 2 years, and expected for the current year?",
    options: {
      lt50000: "Under $50,000",
      "50000-74999": "$50,000 - $74,999",
      "75000-99999": "$75,000 - $99,999",
      "100000-199999": "$100,000 - $199,999",
      "200000-349999": "$200,000 - $349,999",
      gt350000: "$350,000+",
    },
    type: "select",
  },
  investment_experience: {
    label: "What is your level of investment experience?",
    options: {
      expert: "Expert",
      high: "High",
      moderate: "Moderate",
      low: "Low",
      none: "None",
    },
    type: "select",
  },
  investment_knowledge: {
    label: "What is your level of investment knowledge?",
    options: {
      excellent: "Excellent",
      good: "Good",
      fair: "Fair",
      limited: "Limited",
    },
    type: "select",
    tooltip: `“Investment knowledge” refers to your knowledge and experience about investments and the capital markets generally.\n\n “Limited” means that you have limited knowledge of the markets and are new to investing or have limited experience with investments.\n\n “Fair” means that you have a basic knowledge of the markets and have some experience with investments.\n\n “Good” means that you have a good working knowledge of the markets and experience with various types of investments.\n\n “Excellent” means that you are very knowledgeable and experienced in all aspects of the markets, including the inherent risks involved with aggressive investment products.`,
  },
  risk_tolerance: {
    label: "What is your investing risk tolerance?",
    options: riskTolerance,
    type: "select",
    tooltip: `“Risk tolerance” refers to the degree of variability in investment returns that you are willing to accept. Factors that will impact your risk tolerance will include the time horizon that you have for your investments, when you may require access to your investment capital, your future earning capacity, and the other assets that you may have. A “low” risk tolerance means your priority is to preserve the capital and you are generally unwilling to accept volatility in the value of the account. A “medium” risk tolerance means you are willing to accept some risk of loss to the capital and are willing to accept some volatility in the value of the account. A “high” risk tolerance means you are willing to accept the risk of a significant loss to the capital and are willing to accept a high level of volatility in the value of the account.`,
  },
  risk_capacity: {
    label: "What is your risk capacity?",
    options: riskCapacity,
    type: "select",
    tooltip: `“Risk capacity” refers to the degree of variability in investment returns that you have the financial capacity to absorb. Factors that will impact your risk capacity will include your current net financial assets, income generating ability over the time horizon that you have for your investments, and when you may require access to your investment capital. A “low” risk capacity means you have a limited ability to absorb losses. A “medium” risk tolerance means you have net financial assets or earnings ability to absorb some risk of loss to the capital. A “high” risk capacity means you have net financial assets, income ability, and limited need to access your investment capital and therefore are able to accept the risk of a significant loss to the capital.`,
  },
  investment_horizon: {
    label: "What is your investment time horizon (years)?",
    options: liquidityRisk,
    type: "select",
  },
  investment_objectives: {
    label: "What is your main investment objective?",
    options: investmentObjective,
    type: "select",
    tooltip: `“Investment objectives” refers to your financial or investment goals in relation to your account with us.\n\n Knowing your investment objective helps us determine if a particular investment would be appropriate for you.\n\n “Safety” means that your primary objective is to ensure that the value of your investments does not decrease.\n\n “Growth" means that your primary objective is to increase the overall value of your investments over time.\n\n “Income” means that your objective is for the investments to generate current income that is available to you.\n\n “Balanced” means that your objective is to achieve some capital growth while generating some current income.`,
  },
  previous_investments: {
    label: "Which of the following have you invested in before?",
    options: {
      stock: "Stocks",
      bonds: "Bonds",
      etfs: "ETFs",
      private_debt: "Private Debt",
      private_equity: "Private Equity",
      crypto_assets: "Crypto Assets",
      alternatives: "Alternatives",
      other: "Other",
    },
    type: "multi-select",
    fields: {
      other_previous_investments: {
        showIf: {
          and: ["other", { in: [{ var: "previous_investments" }] }],
        },
        label: "Please specify which other asset class(es) you've invested in before.",
        type: "text-field",
      },
    },
  },
  employment_status: {
    label: "What is your current employment status?",
    options: {
      student: "Student",
      part_time: "Part-time",
      full_time: "Full-time",
      retired: "Retired",
      unemployed: "Unemployed",
    },
    type: "select",
  },
  is_registrant: {
    label: "Are you registrant?",
    type: "boolean",
    tooltip:
      "A registrant is an individual registered with a provincial securities regulator to conduct activities under Canadian securities legislation such as trading or advising on securities. A registrant could be a dealing representative, mutual funds dealer, or registered financial advisor, and has a 6-digit registration number in the National Research Database (NRD)",
  },
  insider_reporting_issuer: {
    label: "Are you an insider of a reporting issuer?",
    type: "boolean",
  },
  insider_reporting_issuer_is_related_to_the_securities_issuer: {
    type: "boolean",
    showIf: {
      and: [{ "==": [{ var: "insider_reporting_issuer" }, true] }],
    },
    label:
      "Is the reporting issuer related to the issuer of securities you are planning to purchase?",
    tooltip:
      "Relationship of the reporting issuer to the issuer of securities you are purchasing includes business or personal relationships between directors, officers, and major shareholders.",
  },
  using_borrowed_money: {
    label: "Are you using borrowed money to purchase securities with Atlas One?",
    type: "boolean",
  },
  politically_exposed: {
    label:
      "Are you, one of your family members or associates a politically exposed person or head of an international organization?",
    type: "boolean",
    tooltip: `A politically exposed person (PEP) or the head of an international organization (HIO) is a person entrusted with a prominent position that typically comes with the opportunity to influence decisions and the ability to control resources. Examples include holders of a government or judicial position, political party, mayor, ambassador or head of a state-owned company - domestically or foreign. The influence and control a PEP or HIO has puts them in a position to impact policy decisions, institutions and rules of procedure in the allocation of resources and finances, which can make them vulnerable to corruption. Family members include: mother or father, child, spouse or common-law partner, spouse’s or common law partner’s mother or father, or brother, sister, half-brother or half-sister.`,
  },
  account_opened_on_behalf: {
    label: "Is this wallet or account being opened on behalf of another person?",
    type: "boolean",
  },
  more_than_one_account_operator: {
    label: "Will any person other than you direct activity in this account?",
    type: "boolean",
    fields: {
      more_than_one_account_operator_relationship: {
        showIf: true,
        type: "select",
        label: "Person directing activity relationship",
        options: {
          spouse: "Spouse",
          relative: "Relative",
          immediate_family: "Immediate family",
          power_of_attorney: "Power of Attorney",
          other: "Other",
        },
      },
      more_than_one_account_operator_name: {
        label: "Person directing activity full name",
        type: "text-field",
        showIf: {
          and: [
            {
              "==": [{ var: "more_than_one_account_operator" }, true],
            },
            {
              in: [
                { var: "more_than_one_account_operator_relationship" },
                ["spouse", "immediate_family"],
              ],
            },
          ],
        },
      },
      more_than_one_account_operator_phone: {
        label: "Person directing activity phone",
        type: "text-field",
        showIf: {
          and: [
            {
              "==": [{ var: "more_than_one_account_operator" }, true],
            },
            {
              in: [
                { var: "more_than_one_account_operator_relationship" },
                ["spouse", "immediate_family"],
              ],
            },
          ],
        },
      },
    },
  },
  account_used_on_behalf: {
    label:
      "Will a third party be using this account, or will you be using this account on behalf of a third party?",
    type: "boolean",
    set: [
      {
        value: true,
        readOnly: true,
        condition: {
          and: {
            in: [
              { var: "more_than_one_account_operator_relationship" },
              ["other", "relative", "power_of_attorney"],
            ],
          },
        },
      },
    ],
    fields: {
      third_party_firstname: {
        showIf: true,
        label: "Third Party First Name",
        type: "text-field",
      },
      third_party_lastname: {
        showIf: true,
        label: "Third Party Last Name",
        type: "text-field",
      },
      third_party_phone: {
        showIf: true,
        label: "Third Party Phone",
        type: "text-field",
      },
      third_party_relationship: {
        showIf: true,
        label: "Third Party Relationship",
        type: "select",
        options: {
          spouse: "Spouse",
          relative: "Relative",
          immediate_family: "Immediate family",
          power_of_attorney: "Power of Attorney",
          other: "Other",
        },
      },
      third_party_relationship_other: {
        label: "Specify Other Relationship",
        type: "text-field",
        showIf: {
          and: [
            { "==": [{ var: "account_used_on_behalf" }, true] },
            { "==": [{ var: "third_party_relationship" }, "other"] },
          ],
        },
      },
      third_party_occupation: {
        showIf: true,
        label: "Third Party Occupation",
        type: "text-field",
      },
      third_party_address: {
        showIf: true,
        label: "Third Party Address",
        type: "address",
      },
    },
  },
  confirm_investment_single_offering: {
    label:
      "I confirm that my investment in a single Offering is less than 10% of my net financial assets.",
    type: "boolean",
  },
  trustee_contact_person: {
    label: "Would you like to add a Trusted Contact Person to the account?",
    type: "boolean",
    tooltip:
      "A Trusted Contact Person (TCP) is optional for you to add. Financial service providers such as Atlas One are in a unique position to notice signs of financial exploitation, vulnerability, or diminished mental capacity because of the interactions we have with our clients and the knowledge we acquire through this relationship. A TCP may assist us to respond to such instances if they should arise during your use of the platform’s services. This is an additional security measure for your account. If you choose to provide a TCP, please notify this person that you have provided their first name and phone number for this purpose.",
    fields: {
      same_as_other_account_operator: {
        label: "Same as person directing activity for account",
        type: "checkbox",
        initialValue: false,
        showIf: {
          and: [
            {
              "==": [{ var: "trustee_contact_person" }, true],
            },
            {
              in: [
                { var: "more_than_one_account_operator_relationship" },
                ["spouse", "immediate_family"],
              ],
            },
          ],
        },
      },
      trustee_contact_person_name: {
        label: "First and last name",
        type: "text-field",
        showIf: {
          and: [
            { "==": [{ var: "trustee_contact_person" }, true] },
            { "==": [{ var: "same_as_other_account_operator" }, false] },
          ],
        },
      },
      trustee_contact_person_phone: {
        label: "Contact telephone number",
        type: "text-field",
        showIf: {
          and: [
            { "==": [{ var: "trustee_contact_person" }, true] },
            { "==": [{ var: "same_as_other_account_operator" }, false] },
          ],
        },
      },
    },
  },
};

export const suitabilityForm: {
  [key in AccountType]: any;
} = {
  individual_investor: individualInvestorSuitabilityForm,
  joint_investor: individualInvestorSuitabilityForm,
  entity_investor: {
    aum: {
      label: "What is the total value of your entity’s Assets Under Management (AUM)?",
      options: {
        lt5M: "less than $5,000,000",
        "5M-10M": "$5,000,000 - $10,000,000",
        "10M-25M": "$10,000,000 - $25,000,000",
        "25M-50M": "$25,000,000 - $50,000,000",
        "50M-100M": "$50,000,000 - $100,000,000",
        "100M-250M": "$100,000,000 - $250,000,000",
        "250M-500M": "$250,000,000 - $500,000,000",
        "500M-1B": "$500,000,000 - $1,000,000,000",
        gt1B: "more than $1,000,000,000",
      },
      type: "select",
    },
    risk_level: {
      label: "What is your investment style?",
      options: {
        conservative: "Conservative",
        moderate: "Moderate",
        aggressive: "Aggressive",
      },
      type: "select",
    },
    risk_capacity: individualInvestorSuitabilityForm["risk_capacity"],
    investment_experience: individualInvestorSuitabilityForm["investment_experience"],
    investment_knowledge: individualInvestorSuitabilityForm["investment_knowledge"],
    risk_tolerance: individualInvestorSuitabilityForm["risk_tolerance"],
    investment_objectives: individualInvestorSuitabilityForm["investment_objectives"],
    investment_horizon: individualInvestorSuitabilityForm["investment_horizon"],
    previous_investments: individualInvestorSuitabilityForm["previous_investments"],
    ownership_interest_primary: {
      label: "Ownership interest of primary account holder?",
      type: "number",
    },
    asset_allocation: {
      optional: true,
      label: "What is your entity's asset allocation? (optional)",
      type: "text-field",
    },
    is_broker: {
      label: "Are you a securities dealer/broker?",
      type: "boolean",
    },
    is_registrant: individualInvestorSuitabilityForm["is_registrant"],
    more_than_25_percent_or_politically_exposed: {
      label:
        "Are any directors or beneficial shareholders holding more than 25%, or one of their family members or associates a politically exposed person or head of an international organization?",
      type: "boolean",
    },
    more_than_one_account_operator:
      individualInvestorSuitabilityForm["more_than_one_account_operator"],
    confirm_investment_single_offering:
      individualInvestorSuitabilityForm["confirm_investment_single_offering"],
  },
  issuer: {},
  admin: {},
  individual_referral_agent: {},
  entity_referral_agent: {},
};

function generateInitialValues(o: any) {
  return Object.entries(o).reduce((o, [key, value]) => {
    if (!(value as any)?.fields) {
      if ((value as any).type === "multi-select") {
        o[key] = (value as any).initialValue || [];
      } else if ((value as any).type === "address") {
        o[key] = (value as any).initialValue || {
          unit: null,
          street: null,
          country: null,
          province: null,
          postalCode: null,
        };
      } else {
        o[key] =
          typeof (value as any).initialValue !== "undefined"
            ? (value as any).initialValue
            : null;
      }
    } else {
      o = { ...o, ...generateInitialValues((value as any)?.fields) };
    }
    return o;
  }, {} as any);
}

export const suitabilityInitialValues: { [key in AccountType]: any } = {
  individual_investor: generateInitialValues(suitabilityForm.individual_investor),
  joint_investor: generateInitialValues(suitabilityForm.joint_investor),
  entity_investor: generateInitialValues(suitabilityForm.entity_investor),
  issuer: {},
  admin: {},
  individual_referral_agent: {},
  entity_referral_agent: {},
};

const addressValidationSchema = (key: string) =>
  Yup.object({
    unit: Yup.string(),
    street: Yup.string()
      .nullable(false)
      .required(`${key.split("_").join(" ")} street required`),
    country: Yup.string()
      .nullable(false)
      .required(`${key.split("_").join(" ")} country required`),
    province: Yup.string()
      .nullable(false)
      .required(`${key.split("_").join(" ")} province required`),
    postalCode: Yup.string()
      .nullable(false)
      .required(`${key.split("_").join(" ")} postal code required`),
  });

function yup(type: string, key?: string) {
  if (type === "address") {
    return key ? addressValidationSchema(key) : Yup.object();
  } else if (type === "multi-select") {
    return Yup.array().min(1);
  } else if (type === "boolean") {
    return Yup.boolean();
  } else if (type === "checkbox") {
    return Yup.boolean();
  } else if (type === "number") {
    return Yup.number();
  } else {
    return Yup.string();
  }
}

function generateValidationSchema(form: any, subform?: any, parent?: string) {
  return Object.entries(subform || form).reduce((o, [key, value]: any[]) => {
    if (!value || value.optional) {
      return o;
    }

    if (typeof value?.showIf !== "undefined") {
      const keys: any =
        typeof value.showIf === "object" ? getAllVarValues(value.showIf) : [parent];

      o[key] = Yup.mixed()
        .nullable()
        .when(keys, {
          is: (...args: any) => {
            let i = 0;
            return typeof value.showIf === "object"
              ? jsonLogic.apply(
                  value.showIf,
                  keys.reduce((o: any, k: any) => {
                    o[k] = args[i];
                    i++;
                    return o;
                  }, {})
                )
              : args[0] === value.showIf;
          },
          then: () =>
            yup((value as any).type, key)
              .nullable(false)
              .typeError(`${key.split("_").join(" ")} required`)
              .required(`${key.split("_").join(" ")} required`),
        });
    } else {
      o[key] = yup((value as any).type)
        .nullable(false)
        .typeError(`${key.split("_").join(" ")} required`)
        .required(`${key.split("_").join(" ")} required`);
    }

    if ((value as any)?.fields) {
      o = {
        ...o,
        ...generateValidationSchema(form, (value as any).fields, key),
      };
    }

    return o;
  }, {} as any);
}

export const suitabilityValidationSchema: { [key in AccountType]: any } = {
  individual_investor: Yup.object(
    generateValidationSchema(suitabilityForm.individual_investor)
  ),
  joint_investor: Yup.object(generateValidationSchema(suitabilityForm.joint_investor)),
  entity_investor: Yup.object(generateValidationSchema(suitabilityForm.entity_investor)),
  issuer: {},
  admin: {},
  individual_referral_agent: {},
  entity_referral_agent: {},
};

export const accessMethodLabels: { [key in VirtualCurrencyAccessMethod]: string } = {
  approved_provider: "Approved Provider",
  whitelisted_wallet: "Whitelisted Wallet",
  acquire_digital_currency: "Acquire Digital Currency",
};

export const signUpLabels: {
  [key in string]: { title: string; subtitle?: string; show: boolean };
} = {
  individual_investor: {
    title: "Investor Account",
    subtitle: "I am an individual/entity planning to invest.",
    show: true,
  },
  entity_investor: {
    title: "Investor Account",
    show: false,
  },
  issuer: {
    title: "Issuer Account",
    subtitle: "I would like to raise funds.",
    show: true,
  },
  joint_investor: {
    title: "Joint Investor",
    show: false,
  },
};

export const permissionLabels: { [key in Permission]: string } = {
  can_edit_account: "Can Edit Account",
  can_edit_offers: "Can Edit Offers",
  can_edit_accounts: "Can Edit Accounts",
  can_edit_transactions: "Can Edit Transactions",
  can_view_transactions: "Can View Transactions",
  can_initiate_investment: "Can Initiate Investment",
  can_assume_role: "Can Assume Role",
  can_view_audit_logs: "Can View Audit Logs",
  can_edit_permissions: "Can Edit Permissions",
  can_view_accounts: "Can View Accounts",
  can_export_account_details: "Can Export Account Details",
  can_edit_account_risk_rating: "Can Edit Account Risk Rating",
};

export const transactionStatusLabels: { [key in InvestmentStatus]: string } = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
  cancelled: "Cancelled",
  closed: "Closed",
  complete: "Complete",
  // "initiate_payment": "Initiate Payment",
  payment_initiated: "Payment Initiated",
  payment_made: "Payment Made",
  payment_received: "Payment Received",
};

export const offerIssuanceLabels: { [key in OfferIssuance]: string } = {
  self_managed: "Self Managed",
  managed: "Managed",
  none: "None",
};

export const companySizeLabels: { [key in CompanySize]: string } = {
  early_revenue_company: "Pre/Early Revenue Company",
  "0-5M": "0-5M",
  "10-50M": "10-50M",
  "50-100M": "50-100M",
  "100M+": "100M+",
};

export const distributionTypeLabels: { [key in DistributionType]: string } = {
  "income-dividend": "Income Dividend",
  "capital-dividend": "Capital Dividend",
};

export const signatureStatusLabels: { [key in string]: string } = {
  not_required: "Not Required",
  declined: "Declined",
  voided: "Voided",
  completed: "Completed",
  signed: "Signed",
  pending: "Pending",
  pending_turn: "Pending Turn",
  required: "Required",
};

export const transactionTypeLabels: { [key in string]: string } = {
  transfer: "Transfer",
  distribution: "Distribution",
  redemption: "Redemption",
  contribution: "Contribution",
  loan_repayment: "Loan Repayment",
};

export const transactionServiceTypeLabels: { [key in TransactionServiceType]: string } = {
  emd: "EMD Transaction",
  broker: "Broker Transaction",
  historic_platform_transaction: "Historic Transaction",
  founder_platform_transaction: "Founder Transaction",
  issuer_sponsored_platform_transaction: "Issuer-Sponsored Transaction",
  crowdfunding_platform_transaction: "Crowdfunding Transction",
};

export const registeredAccountTypeLabels: { [key in RegisteredAccountType]: string } = {
  tfsa: "TFSA",
  fhsa: "FHSA",
  rrsp: "RRSP",
  spousal_rrsp: "Spousal RRSP",
  lira: "LIRA",
  lrsp: "LRSP",
  rrfp: "RRFP",
  rrif: "RRIF",
  resp: "RESP",
  spousal_rrif: "Spousal RRIF",
  lif: "LIF",
  rlif_federal: "RLIF (Federal)",
  rlsp_federal: "RLSP (Federal)",
};

export const trustCompanyLabels: { [key in TrustCompanyIdentifier]: string } = {
  olympia_trust: "Olympia Trust",
  odyssey_trust: "Odyssey Trust",
  western_pacific_trust: "Western Pacific Trust",
};

export const offerStatusLabels: { [key in OfferStatus]: string } = {
  active: "Active",
  pending: "Coming Soon",
  paused: "Paused",
  inactive: "Inactive",
  closed: "Closed",
};

export const offerTypeLabels: { [key in OfferType]: string } = {
  debt: "Debt",
  equity: "Equity",
  fund: "Fund",
};

export const accreditationOptions = [
  { value: "accredited", label: "Accredited" },
  { value: "eligible", label: "Eligible" },
  { value: "neither", label: "Neither" },
  { value: "ff&ba", label: "Using FFB&BA" },
];

export const offerSecurityCodeName = {
  NOT: "Notes",
  BOND: "Bonds",
  CER: "Certificates",
  CMS: "Common shares",
  CVD: "Convertible debentures",
  CVN: "Convertible notes",
  CVP: "Convertible preferred shares",
  DCT: "Digital coins or tokens",
  DEB: "Debentures",
  DRS: "Depository receipts e.g. ADRs",
  FTS: "Flow-Through Shares",
  FTU: "Flow-Through Units",
  LPU: "LP units and interests incl. capital commitments",
  MTG: "Mortgages excl. syndicated mortgages",
  OPT: "Options",
  PRS: "Preferred Shares",
  RTS: "Rights",
  SMG: "Syndicated mortgages",
  SUB: "Subscription receipts",
  UBS: "Units of bundled securities",
  UNT: "Units",
  WNT: "Warrants incl. special warrants",
  OTH: "Other",
};

export const investmentOptions = {
  investmentObjective: [
    { value: 'Income', label: 'Income' },
    { value: 'Balanced', label: 'Balanced' },
    { value: 'Growth', label: 'Growth' }
  ],
  timeHorizon: [
    { value: '0-2', label: '0-2 Years' },
    { value: '3-5', label: '3-5 Years' },
    { value: '5+', label: '5+ years' }
  ],
  targetReturn: [
    { value: '5-10%', label: '5-10%' },
    { value: '11-15%', label: '11-15%' },
    { value: '16-20%', label: '16-20%' },
    { value: '20%+', label: '20%+' },
  ],
};

export const riskOptions = {
  liquidityRisk: [
    { value: 'Low', label: 'Low' },
    { value: 'Medium', label: 'Medium' },
    { value: 'High', label: 'High' },
  ],
  level: [
    { value: 'Low', label: 'Low' },
    { value: 'Low-Medium', label: 'Low-Medium' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Medium-High', label: 'Medium-High' },
    { value: 'High', label: 'High' },
  ],
  tolerance: [
    { value: 'Very Low', label: 'Very Low' },
    { value: 'Low', label: 'Low' },
    { value: 'Moderate', label: 'Moderate' },
    { value: 'High', label: 'High' },
    { value: 'Very High', label: 'Very High' },
  ],
  appetite: [
    { value: 'Risk Averse', label: 'Risk Averse' },
    { value: 'Conservative', label: 'Conservative' },
    { value: 'Balanced', label: 'Balanced' },
    { value: 'Growth', label: 'Growth-Oriented' },
    { value: 'Aggressive', label: 'Aggressive' },
  ],
  capacity: [
    { value: 'Very Limited', label: 'Very Limited' },
    { value: 'Limited', label: 'Limited' },
    { value: 'Moderate', label: 'Moderate' },
    { value: 'High', label: 'High' },
    { value: 'Very High', label: 'Very High' },
  ],
};
